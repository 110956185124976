<template>
    <v-container fluid>
        <v-row v-if="isLogged">
            <v-col cols="12">
                <v-container fluid>
                    <v-row class="mt-0">
                        <v-col cols="12" lg="3" md="6">
                            <v-autocomplete v-model="progettiChosen"
                                            :items="elencoProgetti"
                                            item-value="_id"
                                            item-text="codice_commessa"
                                            dense
                                            filled
                                            chips
                                            small-chips
                                            label="Progetti"
                                            multiple/>
                        </v-col>
                        <v-col cols="12" lg="3" md="6">
                            <v-autocomplete v-model="particolariChosen"
                                            :items="elencoParticolari"
                                            item-value="_id"
                                            item-text="part_number"
                                            dense
                                            filled
                                            chips
                                            small-chips
                                            label="Particolari"
                                            multiple />
                        </v-col>
                        <v-col cols="12" lg="3" md="6">
                            <v-menu ref="menu"
                                    v-model="menuDataGrafico"
                                    :close-on-content-click="true"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="meseChosen"
                                                  label="Scegli mese"
                                                  prepend-icon="mdi-calendar"
                                                  readonly
                                                  v-bind="attrs"
                                                  v-on="on"/>
                                </template>
                                <v-date-picker v-model="meseChosen"
                                               type="month"
                                               no-title
                                               scrollable
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" lg="3" md="6">
                            <v-btn elevation="0"
                                   :ripple="false"
                                   height="43"
                                   class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm my-1"
                                   color="success"
                                   @click="initDataGraphs()"
                            >
                                Applica
                            </v-btn>
                        </v-col>
                        <v-col cols="12" lg="3" md="6" class="py-0">
                            <v-card class="card-shadow mb-6">
                                <v-card-text class="card-stats-padding">
                                    <v-row no-gutters>
                                        <v-col>
                                            <div class="font-weight-600 text-muted text-uppercase text-h5">
                                                Di questo mese
                                            </div>
                                            <p class="font-weight-600 text-h2 text-typo mb-0">
                                                <span class="text-success">{{ pezziFattiMese }}</span>
                                                Pezzi Prodotti
                                            </p>
                                        </v-col>
                                        <v-col cols="2" class="text-right">
                                            <v-avatar color="red">
                                                <v-icon size="20" class="text-white">
                                                    fas fa-puzzle-piece
                                                </v-icon>
                                            </v-avatar>
                                        </v-col>
                                    </v-row>
                                    <p class="mt-4 mb-0 text-body font-weight-light">
                                        <span :class="percentualePezziFatti > 0 ? 'text-success me-2': 'text-warning me-2'">
                                            {{ percentualePezziFatti > 0 ? "+" + percentualePezziFatti: "-" + percentualePezziFatti }}%
                                        </span>
                                        Rispetto lo scorso mese
                                    </p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" lg="3" md="6" class="py-0">
                            <v-card class="card-shadow mb-6">
                                <v-card-text class="card-stats-padding">
                                    <v-row no-gutters>
                                        <v-col>
                                            <div class="font-weight-600 text-muted text-uppercase text-h5">
                                                Di questo mese
                                            </div>
                                            <p class="font-weight-600 text-h2 text-typo mb-0">
                                                <span class="text-success">{{ controlliFattiMese }}</span>
                                                Controlli Fatti
                                            </p>
                                        </v-col>
                                        <v-col cols="2" class="text-right">
                                            <v-avatar color="red">
                                                <v-icon size="20" class="text-white">
                                                    fas fa-clipboard-list
                                                </v-icon>
                                            </v-avatar>
                                        </v-col>
                                    </v-row>
                                    <p class="mt-4 mb-0 text-body font-weight-light">
                                        <span :class="percentualeControlliFatti > 0  ? 'text-success me-2' : 'text-warning me-2'">
                                            {{ percentualeControlliFatti > 0 ? "+" + percentualeControlliFatti : "-" + percentualeControlliFatti }}%
                                        </span>
                                        Rispetto lo scorso mese
                                    </p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" lg="3" md="6" class="py-0">
                            <v-card class="card-shadow mb-6">
                                <v-card-text class="card-stats-padding">
                                    <v-row no-gutters>
                                        <v-col>
                                            <div class="font-weight-600 text-muted text-uppercase text-h5">
                                                Di questo mese
                                            </div>
                                            <p class="font-weight-600 text-h2 text-typo mb-0">
                                                <span style="color: LightGrey">
                                                    {{ nonConformiMeseTotale }}
                                                </span>
                                                Pezzi non conformi
                                            </p>
                                        </v-col>
                                        <v-col cols="2" class="text-right pr-0 mr-0">
                                            <v-avatar color="red">
                                                <v-icon size="20" class="text-white">
                                                    fas fa-exclamation-circle
                                                </v-icon>
                                            </v-avatar>
                                        </v-col>
                                    </v-row>
                                    <p class="mt-4 mb-0 text-body font-weight-light">
                                        <span :class="percentualeNonConformi > 0 ? 'text-warning me-2' : 'text-success me-2'">
                                            {{percentualeNonConformi > 0? "+" + percentualeNonConformi: "-" + percentualeNonConformi }}%
                                        </span>
                                        Rispetto lo scorso mese
                                    </p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <h3>Passi Critici</h3>
                            <v-data-table no-data-text="Ancora nessun dato"
                                          :headers="headers"
                                          :items="nonConformiMese"
                                          :items-per-page="15"
                                          hide-default-footer
                                          class="table thead-light"
                                          mobile-breakpoint="0"
                            >
                                <template v-slot:item.seriali_pezzi="{ item }">
                                    <span v-if="item.seriali_pezzi && item.seriali_pezzi.length">
                                        {{ item.seriali_pezzi.toString() }}
                                    </span>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12" class="text-center">
                <router-link to="/login">
                    Effettua il login per continuare
                </router-link>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import apiProgetti from "@/js/pages/progetti";
import apiParticolari from "@/js/pages/particolari";

export default {
    name: "Home",
    data() {
        return {
            isLogged: false,
            ruolo: this.$store.state.ruolo,
            token: this.$cookies.get("token_goldencar"),
            pezziFattiMese: 0,
            controlliFattiMese: 0,
            nonConformiMeseTotale: 0,
            nonConformiMese: [],
            pezziFattiScorsoMese: 0,
            controlliFattiScorsoMese: 0,
            nonConformiScorsoMeseTotale: 0,
            nonConformiScorsoMese: [],
            percentualePezziFatti: 0,
            percentualeControlliFatti: 0,
            percentualeNonConformi: 0,
            menuDataGrafico: false,
            meseChosen: new Date().getFullYear() + "-" + (new Date().getMonth() + 1),
            elencoProgetti: [],
            progettiChosen: [],
            elencoParticolari: [],
            particolariChosen: [],
            headers: [
                {
                    text: "Nome Passo",
                    align: "start",
                    sortable: true,
                    value: "_id.nome",
                    width: "40%",
                },
                { text: "Conteggio", value: "conto", width: "10%" },
                { text: "C. Commessa", value: "codice_commessa", width: "10%" },
                { text: "Part Number", value: "part_number", width: "10%" },
                { text: "Seriali", value: "seriali_pezzi", width: "20%" },
            ],
        };
    },
    watch: {
        async $route(to, from) {
            if (this.$store.state.ruolo === "Amministratore") this.isLogged = true;
        },
        "$store.state.ruolo"() {
            this.ruolo = this.$store.state.ruolo;
        },
        async progettiChosen() {
            this.elencoParticolari = [];
            this.particolariChosen = [];
            this.elencoParticolari = await apiParticolari.getAllFromIds(
                this.token,
                this.progettiChosen || []
            );
        },
    },
    async created() {
        if (this.ruolo === "Operatore") await this.$router.push("/listProgettiOperatore");
        else if (this.ruolo === "Amministratore") this.isLogged = true;

        await this.initDataGraphs();

        await Promise.all([
            new Promise(async () => {
                this.elencoProgetti = await apiProgetti.getAll(this.token);
            }),
            new Promise(async () => {
                this.elencoParticolari = await apiParticolari.getAllFromIds(
                    this.token,
                    []
                );
            }),
        ]);
    },
    methods: {
        async initDataGraphs() {
            this.pezziFattiMese = 0;
            this.controlliFattiMese = 0;
            this.nonConformiMese = [];
            this.pezziFattiScorsoMese = 0;
            this.controlliFattiScorsoMese = 0;
            this.nonConformiScorsoMese = [];
            [
                this.pezziFattiMese,
                this.controlliFattiMese,
                this.nonConformiMese,
                this.pezziFattiScorsoMese,
                this.controlliFattiScorsoMese,
                this.nonConformiScorsoMese,
            ] = (
                await apiProgetti.getDatiGrafici(
                    this.token,
                    this.progettiChosen,
                    this.particolariChosen,
                    this.meseChosen
                )
            ) || [0, 0, [], 0, 0, []];

            this.percentualePezziFatti = parseFloat(
                (
                    ((this.pezziFattiMese - this.pezziFattiScorsoMese) * 100) /
                    (this.pezziFattiScorsoMese > this.pezziFattiMese
                        ? this.pezziFattiScorsoMese
                        : this.pezziFattiMese)
                ).toFixed(2)
            ) || 0;

            this.percentualeControlliFatti = parseFloat(
                (
                    ((this.controlliFattiMese - this.controlliFattiScorsoMese) * 100) /
                    (this.controlliFattiScorsoMese > this.controlliFattiMese
                        ? this.controlliFattiScorsoMese
                        : this.controlliFattiMese)
                ).toFixed(2)
            ) || 0;

            let sommaNonConformiMese = (this.nonConformiMese.map((x) => x["seriali_pezzi"].length) || [])
                .reduce((partial_sum, a) => partial_sum + a, 0);
            this.nonConformiMeseTotale = sommaNonConformiMese;

            let sommaNonConformiScorsoMese = (this.nonConformiScorsoMese.map((x) => x["seriali_pezzi"].length) || [])
                .reduce((partial_sum, a) => partial_sum + a, 0);
            this.nonConformiScorsoMeseTotale = sommaNonConformiScorsoMese;

            this.percentualeNonConformi = parseFloat(
                (
                    ((sommaNonConformiMese - sommaNonConformiScorsoMese) * 100) /
                    (sommaNonConformiScorsoMese > sommaNonConformiMese
                        ? sommaNonConformiScorsoMese
                        : sommaNonConformiMese)
                ).toFixed(2)
            ) || 0;

            console.log( this.nonConformiMese)
            for (let nonconforme of this.nonConformiMese) {
                let id_particolare = undefined;
                if (
                    nonconforme._id &&
                    nonconforme._id.id_particolare
                ) id_particolare = nonconforme._id.id_particolare;

                let part_number = '';
                let codice_commessa = '';
                if (
                    nonconforme.progetto &&
                    nonconforme.progetto[0]
                ) {
                    if (nonconforme.progetto[0].codice_commessa)
                        codice_commessa = nonconforme.progetto[0].codice_commessa;

                    if (nonconforme.progetto[0].particolari) {
                        let getParticolare = nonconforme.progetto[0].particolari.find(obj => {
                            return String(obj._id) === String(id_particolare);
                        });
                        if (
                            getParticolare &&
                            getParticolare.part_number
                        ) part_number = getParticolare.part_number;
                    }
                }

                nonconforme.codice_commessa = codice_commessa;
                nonconforme.part_number = part_number;
            }
        }
    }
};
</script>
