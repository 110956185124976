import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import vuetify from './plugins/vuetify';
import { generalFunction } from './js/generalFunction.js';
import { EventBus } from '@/eventBus';
import { store } from "@/store";
import DashboardPlugin from "./plugins/dashboard-plugin";

if (typeof io !== 'undefined')
  Vue.prototype.Socket = io(process.env.VUE_APP_ENDPOINT_API);

/*console.log(process.env.VUE_APP_ENDPOINT_API);
Vue.use(new VueSocketIO({
  debug: true,
  connection: process.env.VUE_APP_ENDPOINT_API, //options object is Optional
  //vuex: {
  //  store,
  //  actionPrefix: "SOCKET_",
  //  mutationPrefix: "SOCKET_"
  //}
  options: { 
    path: "/socket.io/",
    transports: ["websocket"],
  } 
}));*/


Vue.use(require('vue-cookies'));

Vue.use(DashboardPlugin);

Vue.config.productionTip = false;

Vue.prototype.generalFunction = generalFunction;
Vue.prototype.EventBus = EventBus;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');