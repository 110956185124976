import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/progetti/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAll(token, ruolo) {
        if(!ruolo) ruolo = '';
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/progetti/get_all?token="+token+"&ruolo="+ruolo).then(response => {
            return response.data;
        });
    },
    async getOne(token, _id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/progetti/get_one?_id="+_id+"&token="+token).then(response => {
            return response.data;
        });
    },
    async getOneSimple(token, _id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/progetti/get_one_simple?_id="+_id+"&token="+token).then(response => {
            return response.data;
        });
    },
    async getOneFromPezzo(token, id_pezzo) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/progetti/get_one_from_pezzo?id_pezzo="+id_pezzo+"&token="+token).then(response => {
            return response.data;
        });
    },
    async updateOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/progetti/update_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/progetti/delete_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getDatiGrafici(token, progettiChosen = [], particolariChosen = [], meseChosen) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/progetti/get_dati_grafici?token="+token+"&id_progetti="+progettiChosen.toString()+"&id_particolari="+particolariChosen.toString()+"&meseChosen="+meseChosen).then(response => {
            return response.data;
        });
    },
}