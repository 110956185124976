import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import { generalFunction } from '@/js/generalFunction.js';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {}
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  {
    path: '/listUtenti',
    name: 'listUtenti',
    component: () => import(/* webpackChunkName: "listUtenti" */ '@/views/ListUtenti.vue'),
    meta: {
      ruolo: ['Amministratore']
    }
  },
  {
    path: '/listProgetti',
    name: 'listProgetti',
    component: () => import(/* webpackChunkName: "listProgetti" */ '@/views/ListProgetti.vue'),
    meta: {
      ruolo: ['Amministratore', 'Operatore']
    }
  },
  {
    path: '/listProgettiOperatore',
    name: 'listProgettiOperatore',
    component: () => import(/* webpackChunkName: "listProgettiOperatore" */ '@/views/ListProgettiOperatore.vue'),
    meta: {
      ruolo: ['Amministratore', 'Operatore']
    }
  },
  {
    path: '/listParticolariOperatore',
    name: 'listParticolariOperatore',
    component: () => import(/* webpackChunkName: "listParticolariOperatore" */ '@/views/ListParticolariOperatore.vue'),
    meta: {
      need: ['id_progetto'],
      ruolo: ['Amministratore', 'Operatore']
    }
  },
  {
    path: '/listPezziOperatore',
    name: 'listPezziOperatore',
    component: () => import(/* webpackChunkName: "listPezziOperatore" */ '@/views/ListPezziOperatore.vue'),
    meta: {
      need: ['id_progetto', 'id_particolare'],
      ruolo: ['Amministratore', 'Operatore']
    }
  },
  {
    path: '/pezzoOperatore',
    name: 'pezzoOperatore',
    component: () => import(/* webpackChunkName: "pezzoOperatore" */ '@/views/PezzoOperatore.vue'),
    meta: {
      need: ['_id'],
      ruolo: ['Amministratore', 'Operatore']
    }
  },{
    path: '/s/:id',
    name: 'stampaPezzo',
    component: () => import(/* webpackChunkName: "stampaPezzo" */ '@/views/StampaPezzo.vue'),
    meta: {
      ruolo: ['Amministratore', 'Operatore']
    }
  },
  {
    path: '/presetPassi',
    name: 'presetPassi',
    component: () => import(/* webpackChunkName: "presetPassi" */ '@/views/PresetPassi.vue'),
    meta: {
      ruolo: ['Amministratore']
    }
  },
  {
    path: '/schedaProgetto',
    name: 'schedaProgetto',
    component: () => import(/* webpackChunkName: "schedaProgetto" */ '@/views/SchedaProgetto.vue'),
    meta: {
      need: ['_id'],
      ruolo: ['Amministratore', 'Operatore']
    }
  },
];

const router = new VueRouter({
  history: true,
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

let ruolo, token;

router.beforeEach( async (to, from, next) => {
  if(from.name == 'Login'){
    token = await generalFunction.getToken();
    ruolo = await generalFunction.getRuolo(token);
  }
  if(!token) token = await generalFunction.getToken();
  if(!ruolo) ruolo = await generalFunction.getRuolo(token);

  let ok = true;
  if(to.meta.need && to.meta.need.length) {
    ok = false;
    for (const paramNeeded of to.meta.need)
      if(to.query && to.query[paramNeeded]) ok = true;
  }
  if(
    to.meta.ruolo &&
    to.meta.ruolo.length &&
    to.meta.ruolo.indexOf(ruolo) === -1
  ) ok = false;

  if(ok) next();
  else if(from.name) next(from.name);
  else next('/');
});

export default router;
