import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/particolari/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAllFromProgetto(token, id_progetto) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/particolari/get_all_from_progetto?token="+token+"&id_progetto="+id_progetto).then(response => {
            return response.data;
        });
    },
    async getAllFromIds(token, id_progetti = []) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/particolari/get_all_from_id_progetti?token="+token+"&id_progetti="+id_progetti.toString()).then(response => {
            return response.data;
        });
    },
    async updateOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/particolari/update_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/particolari/delete_one?token="+token, params).then(response => {
            return response.data;
        });
    },
}