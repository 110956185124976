import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import minifyTheme from 'minify-css-string'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    options: { minifyTheme },
    themes: {
      light: {//da modificare anche in src/assets/styles/argon/_variables.scss
        "default": '#172b4d',
        "primary": '#222',
        "warning": '#fb6340',
        "danger": '#f5365c',
        "error": '#f5365c',
        "info": '#11cdef',
        "accent": '#11cdef',
        "success": '#2dce89',
        "secondary": '#bf9d3b',
      },
    },
  },
});