<template>
  <v-app>
    <drawer :drawer="drawer"></drawer>
    <v-main>
      <div
        @click="drawer = null"
        v-if="drawer"
        class="position-absolute drawer-state"
      ></div>
      <app-bar
        background="bg-primary"
        has-bg
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
      ></app-bar>
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <router-view></router-view>
      </fade-transition>
    </v-main>
    <Alert></Alert>
  </v-app>
</template>
<style lang="scss">
  @import "@/assets/styles/main.scss";
</style>

<script>
import apiUtenti from '@/js/pages/utenti';
const Alert = () => import('@/components/Alert');
const Drawer = () => import('@/components/Drawer');
const AppBar = () => import('@/components/AppBar');
import { FadeTransition } from "vue2-transitions";

export default {
  name: 'App',
  components: {
    Alert,
    Drawer,
    AppBar,
    FadeTransition
  },
  watch:{
    async $route (to, from){
      this.token = this.$cookies.get('token_goldencar');
      if(from.name === 'Login' && this.token){
        let datiUtente = await apiUtenti.getUtenteFromToken(this.token);
        this.nomeUtente = datiUtente ? datiUtente.nome : '';
        this.$store.state.token = this.token;
        this.$store.state.ruolo = datiUtente['ruolo'];
        this.$store.state.id_utente = datiUtente['_id'];
      }
      if(from.name === "pezzoOperatore")
        this.Socket.emit('askForCheckVistaPezzo');
    }
  },
  data() {
    return {
      token: this.$cookies.get('token_goldencar'),
      nomeUtente: '',
      drawer: null,
    }
  },
  async created() {
    if(!this.token) this.token = this.$cookies.get('token_goldencar');
    if(this.token) {
      let datiUtente = await apiUtenti.getUtenteFromToken(this.token);
      this.nomeUtente = datiUtente ? datiUtente.nome : '';
      this.$store.state.token = this.token;
      this.$store.state.ruolo = datiUtente['ruolo'];
      this.$store.state.id_utente = datiUtente['_id'];
    }
  },
};
</script>
